// ////////////////////////////////////////////////////////////////////////////////
//
// 共通
//
// ////////////////////////////////////////////////////////////////////////////////

$(function() {
  initSlider();
  initPartialView();
  confirmAcceptance();
  controlThread();
  initProgressBar();
  initApplicationInfoProgress();
});

// $(window).on('load', function () {
// });

// $(window).on('load resize', function () {
// });



/* ************************************************************ */
/*
/* スライダー
/*
/* ************************************************************ */
// assets/javascripts/slick/slick.min.js

function initSlider() {
  const slider     = '.js-slider';
  const slickClass = 'slick-slider';

  let sliderCenterPadding;

  if ($(slider).length) {
    const sliderRoot          = document.querySelector(slider);
    const sliderRootStyle     = getComputedStyle(sliderRoot);

    $(window).on('load resize', function () {
      sliderCenterPadding = sliderRootStyle.getPropertyValue('--bs-slider-center-padding');

      $(slider).each(function() {
        if ( ! ($(this).hasClass(slickClass))) {
          $(this).slick({
            arrows: false,
            centerMode: true,
            centerPadding: sliderCenterPadding,
            infinite: false,
            swipeToSlide: true,
            variableWidth: true,
            zIndex: 1,

            // mobileFirst: true,
          });
        }
      });
    });
  }
};


/* ************************************************************ */
/*
/* リストの一部のみ表示
/*
/* ************************************************************ */

function initPartialView() {
  const wrapper       = $('.js-partial-view');

  if (wrapper.length) {
    wrapper.each(function() {
      const list          = $(this).children('.js-partial-view-list');
      const listItem      = list.children('.js-partial-view-list-item');
      const listItemNum   = listItem.length;
      const shownNum      = list.data('pv-shown');

      const toggler       = $(this).find('.js-partial-view-toggler');
      const togglerBtn    = toggler.find('.js-partial-view-toggler-btn');
      const togglerBtnNum = togglerBtn.find('.js-partial-view-toggler-btn-num');

      const hiderClass    = 'is-hidden';
      const bsHiderClass  = 'd-none';

      let hiddenItemsNum = listItemNum - shownNum;

      if(hiddenItemsNum > 0) {
        showLess();
        toggler.removeClass(bsHiderClass);
      } else {
        toggler.addClass(bsHiderClass);
      }

      togglerBtn.on('click', function () {
        if(hiddenItemsNum > 0 && listItem.hasClass(hiderClass)) {
          showAll();
        } else {
          showLess();
        }
      });

      function showAll() {
        listItem.removeClass(hiderClass);
        togglerBtnNum.text( '一部のみ表示' );
      }

      function showLess() {
        listItem.eq(shownNum - 1).nextAll(listItem).addClass(hiderClass);
        togglerBtnNum.text( '残り' +  hiddenItemsNum + '件を表示');
      }
    });
  }
}


/* ************************************************************ */
/*
/* 承諾確認
/*
/* ************************************************************ */

function confirmAcceptance() {
  const check       = $('.js-acceptance-check');

  if (check.length) {
    const relName      = 'acc-rel';

    check.each(function() {
      const relVal     = $(this).data(relName);
      const target     = $('.js-acceptance-target[data-' + relName + '="' + relVal + '"]');

      $(window).on('load', function () {
        $(check).change();
      });

      $(check).on('change', function () {
        if ($(this).prop('checked') != true) {
          disableTarget();
        } else {
          enableTarget();
        }
      });

      function disableTarget() {
        target.each(function() {
          const targetTag  = $(this).prop('tagName');
          const targetType = $(this).attr('type');

          if (targetTag == 'BUTTON' || targetType == 'submit') {
              $(this).prop('disabled', true);
            } else {
              $(this).addClass('is-disabled');
            }
        });
      }

      function enableTarget() {
        target.each(function() {
          const targetTag  = $(this).prop('tagName');
          const targetType = $(this).attr('type');

          if (targetTag == 'BUTTON' || targetType == 'submit') {
              $(this).prop('disabled', false);
            } else {
              $(this).removeClass('is-disabled');
            }
        });
      }
    });
  }
}


/* ************************************************************ */
/*
/* スレッド
/*
/* ************************************************************ */

function controlThread() {
  const thread     = '.js-thread';

  const input      = '.js-thread-input';
  const opener     = '.js-thread-input-opener';
  const closer     = '.js-thread-input-closer';

  const scrollerUp     = '.js-thread-scroller-up';
  const scrollerDown   = '.js-thread-scroller-down';

  const hiderClass = 'is-hidden';

  if ($(thread).length) {
    // スレッドが存在するとき、画面を最下部までスクロールする
    $(window).on('load', function () {
      scrollDown();
    });

    // if ($(input).length && $(opener).length) {
    if ($(input).length) {
      // ////////////////////////
      // [削除] 231207-01
      // // 入力欄が存在するとき、ナビゲーションを隠す
      // disableNav();
      // ////////////

      if ($(opener).length) {
        // 入力欄の呼び出しボタンが存在するとき
        // 入力欄を一旦隠す
        $(input).addClass(hiderClass);

        $(opener).on('click', function () {
          // 呼び出しボタンのクリック時、
          // 入力欄を表示し、呼び出しボタンを隠す
          $(input).removeClass(hiderClass);
          $(opener).addClass(hiderClass);

          // 画面を最下部（≒入力欄）までスクロールする
          scrollDown();
        });

        if ($(closer).length) {
          $(closer).on('click', function () {
            // 入力欄を閉じるとき、
            // 入力欄を隠し、呼び出しボタンを表示する
            $(input).addClass(hiderClass);
            $(opener).removeClass(hiderClass);
          });
        }
      }
    }
  }

  if ($(scrollerUp).length) {
    $(scrollerUp).on('click', function () {
      scrollUp();
    });
  }

  if ($(scrollerDown).length) {
    $(scrollerDown).on('click', function () {
      scrollDown();
    });
  }
}


/* ************************************************************ */
/*
/* スクロール
/*
/* ************************************************************ */

function scrollUp() {
  setTimeout(function() {
    window.scroll(0, 0);
  }, 0);
}

function scrollDown() {
  setTimeout(function() {
    window.scroll(0, $(document).height());
  }, 0);
}


/* ************************************************************ */
/*
/* プログレスバー（汎用）
/*
/* ************************************************************ */

function initProgressBar(targetProgress, targetProgressBar) {
  const progress     = $('.js-progress');
  if (! targetProgress) {
    targetProgress = progress;
  }

  let valueMax;
  let valueNow;
  let progressBarWidth;

  if (targetProgress.length) {
    targetProgress.each(function() {
      valueMax = $(this).attr('aria-valuemax');
      valueNow = $(this).attr('aria-valuenow');
      progressBarWidth = valueNow / valueMax * 100 + '%';

      const progressBar  = $(this).find('.js-progress-bar');
      if (! targetProgressBar) {
        targetProgressBar = progressBar;
      }
      targetProgressBar.css('width', progressBarWidth);
    });
  }
}


/* ************************************************************ */
/*
/* プログレスバー（応募状況）
/*
/* ************************************************************ */

function initApplicationInfoProgress() {
  // const list           = '.js-application-list';
  const primaryList    = '.js-application-list-primary';
  const secondaryList  = '.js-application-list-secondary';
  const listItem     = '.js-application-list-item';

  const wrapper      = '.js-application-information-wrapper';
  const container    = '.js-application-information-container';

  const progress     = '.js-application-information-progress';
  const progressBar  = '.js-application-information-progress-bar';

  const toggler        = '.js-application-information-toggler';
  const togglerBtn     = '.js-application-information-toggler-btn';
  const togglerBtnText = '.js-application-information-toggler-btn-text';

  const scroller      = '.js-application-information-scroller';
  const scrollerBtn   = '.js-application-information-scroller-btn';

  const secondaryListToggler         = '.js-application-list-secondary-toggler';
  const secondaryListTogglerBtn      = '.js-application-list-secondary-toggler-btn';
  const secondaryListTogglerBtnText  = '.js-application-list-secondary-toggler-btn-text';

  const collapseClass   = 'is-collapsed';
  const bsHiderClass    = 'd-none';

  let containerWidth;
  let containerScrollWidth;

  let progressBarValueNow;
  let progressBarWidth;
  let progressBarFirstUnitWidth;
  let progressBarUnitWidth;

  const containerRoot       = document.querySelector(container);
  if (containerRoot == null) return;
  const containerRootStyle  = getComputedStyle(containerRoot);
  progressBarFirstUnitWidth = containerRootStyle.getPropertyValue('--bs-application-information-progress-list-offset-x');
  progressBarUnitWidth      = containerRootStyle.getPropertyValue('--bs-application-information-progress-list-item-width') + '/ 2';


  initApplicationInfoToggler();
  initApplicationInfoScroller();
  switchApplicationInfoScrollerBtn();
  initApplicationSecondaryListToggler();
  setApplicationInfoProgressBar();



  function initApplicationInfoToggler() {
    // ステータス詳細の表示・非表示切替を有効化する

    if ($(toggler).length) {
      $(toggler).each(function() {
        const targetWrapper       = $(this).siblings(wrapper);

        if ($(this).hasClass(bsHiderClass)) {
          $(this).removeClass(bsHiderClass);
        }
        initApplicationInfoDefaultView();

        $(this).find(togglerBtn).on('click', function () {
          if (targetWrapper.length) {
            if (targetWrapper.hasClass(collapseClass)) {
              showDetail(targetWrapper, $(this).find(togglerBtnText));
              initApplicationInfoProgressBar(targetWrapper, targetWrapper.find(progress), targetWrapper.find(progressBar));
              switchApplicationInfoScrollerBtn();
            } else {
              showSummary(targetWrapper, $(this).find(togglerBtnText));
              initProgressBar(targetWrapper.find(progress), targetWrapper.find(progressBar));
            }
          }
        });
      });
    }

    function initApplicationInfoDefaultView() {
      // プライマリリストの 2 番目以降のリストアイテムは、初期表示を概要のみとする
      $(primaryList).each(function() {
        showDetail(
          $(this).find(listItem).eq(0).find(wrapper),
          $(this).find(listItem).eq(0).find(togglerBtnText)
        );

        showSummary(
          $(this).find(listItem).slice(1).find(wrapper),
          $(this).find(listItem).slice(1).find(togglerBtnText)
        );
      });

      // セカンダリリストのリストアイテムは、すべて初期表示を概要のみとする
      $(secondaryList).each(function() {
        showSummary(
          $(this).find(listItem).find(wrapper),
          $(this).find(listItem).find(togglerBtnText)
        );
      });
    }

    function showDetail(targetBlock, targetBtnText) {
      targetBlock.removeClass(collapseClass);
      initApplicationInfoProgressPosX(targetBlock);
      targetBtnText.text( '進捗の概要のみ表示' );
    }

    function showSummary(targetBlock, targetBtnText) {
      targetBlock.addClass(collapseClass);
      targetBtnText.text( '進捗の詳細を表示' );
    }
  }

  function initApplicationInfoScroller() {
    // ステータス詳細の横スクロールボタンを有効化する

    if ($(scroller).length) {
      $(scroller).each(function() {
        if ($(this).hasClass(bsHiderClass)) {
          $(this).removeClass(bsHiderClass);
        }

        $(scrollerBtn).each(function() {
          let str = '';
          for (let i = 1; i <= 100; i++) { str += (i%10).toString(); }
          let timerID;
          const offset = 10;
          const ms     = 10;

          $(this).on({
            'mousedown':function() {
              const targetContainer   = $(this).closest(wrapper).find(container);
              let w = targetContainer.prop('scrollWidth') - targetContainer.prop('clientWidth');
              const direction = $(this).data('scroll-direction');

              timerID = setInterval( function() {
                let pos = targetContainer.scrollLeft() + (direction == 'left' ? -1 : 1) * offset;

                if (pos < 0) {
                  pos = 0;
                  clearInterval(timerID);
                };
                if (pos > w) {
                  pos = w;
                  clearInterval(timerID);
                };
                targetContainer.scrollLeft(pos);
              }, ms);
            },
            'mouseup':function() {
              clearInterval(timerID);
            }
          });
        });
      });
    }
  }

  function switchApplicationInfoScrollerBtn() {
    // 横スクロールの状態を取得し、スクロールボタンを有効化・無効化する

    $(container).each(function() {
      let containerScrollMaxNum = $(this).prop('scrollWidth') - $(this).prop('clientWidth');
      let containerPosX;
      
      const targetScrollerBtnLeft = $(this).siblings(scroller).find(scrollerBtn + '[data-scroll-direction="left"]');
      const targetScrollerBtnRight = $(this).siblings(scroller).find(scrollerBtn + '[data-scroll-direction="right"]');

      if (containerScrollMaxNum <= 0) {
        targetScrollerBtnLeft.prop('disabled', true);
        targetScrollerBtnRight.prop('disabled', true);
      }

      $(this).on('scroll', function () {
        containerPosX = $(this).scrollLeft();

        if (containerPosX === 0) {
          targetScrollerBtnLeft.prop('disabled', true);
        } else {
          targetScrollerBtnLeft.prop('disabled', false);
        }

        if (containerPosX === containerScrollMaxNum) {
          targetScrollerBtnRight.prop('disabled', true);
        } else {
          targetScrollerBtnRight.prop('disabled', false);
        }
      });
    });
  }

  function setApplicationInfoProgressBar() {
    // 各プログレスバーの初期状態を設定する

    $(wrapper).each(function() {
      if ( ! $(this).hasClass(collapseClass)) {
        initApplicationInfoProgressBar($(this), $(this).find(progress), $(this).find(progressBar));
      } else {
        initProgressBar($(this).find(progress), $(this).find(progressBar));
      }
    });
  }

  function initApplicationInfoProgressBar(targetWrapper, targetProgress, targetProgressBar) {
    // ステータス詳細のプログレスバーを設定・実行する

    progressBarValueNow = targetProgress.attr('aria-valuenow');

    if (progressBarValueNow == 1) {
      progressBarWidth = progressBarFirstUnitWidth;
    } else if (progressBarValueNow > 1) {
      progressBarValueNow = progressBarValueNow - 1;
      progressBarWidth = 'calc(' + progressBarFirstUnitWidth + ' + ' + progressBarUnitWidth + ' * ' + progressBarValueNow + ')';
    }
    targetProgressBar.css('width', progressBarWidth);

    initApplicationInfoProgressPosX(targetWrapper);
  }

  function initApplicationInfoProgressPosX(targetContainer) {
    // ステータス詳細の横スクロール位置を初期化する

    containerWidth = targetContainer.find(container).outerWidth(true);
    containerElm = targetContainer.find(container).get(0);
    containerScrollWidth = containerElm.scrollWidth;

    targetContainer.find(container).scrollLeft(containerScrollWidth - containerWidth);
  }

  function initApplicationSecondaryListToggler() {
    // セカンダリリストの表示・非表示切替を有効化する

    if ($(secondaryList).length && $(secondaryListToggler).length) {
      $(secondaryListToggler).removeClass(bsHiderClass);
      hideSecondaryList();

      $(secondaryListTogglerBtn).on('click', function () {
        if ($(secondaryList).hasClass(bsHiderClass)) {
          showSecondaryList();
        } else {
          hideSecondaryList();
        }
      });

      function showSecondaryList() {
        $(secondaryList).removeClass(bsHiderClass);
        $(secondaryListTogglerBtnText).text( '主な応募状況のみ表示' );
      }

      function hideSecondaryList() {
        $(secondaryList).addClass(bsHiderClass);
        $(secondaryListTogglerBtnText).text( 'すべての応募状況を表示' );
      }
    }
  };
}


/* ************************************************************ */
/*
/* ナビゲーション
/*
/* ************************************************************ */

function disableNav() {
  const nav           = '.js-nav';
  const disabledClass = 'is-disabled';

  if ($(nav).length) {
    $(nav).addClass(disabledClass);
  }
}


// /* ************************************************************ */
// /*
// /* メールアドレスのデコード
// /*
// /* ************************************************************ */

// $(window).on('load', function() {
//     "use strict";
//     const obfuscate = '.js-mailaddress-decoder';

//     if( $(obfuscate).length ){
//         const obfuscateStr = $(obfuscate).data('obfuscatekey');
//         $(obfuscate).attr('href', atob('bWFpbHRvOg==') + atob(obfuscateStr));
//         $(obfuscate).html(atob(obfuscateStr));
//     }
// });


